<template>
  <div class="draft-event-review">
    <j-form model="drafts.draft" hide-errors>

        <!-- Headline Act -->
        <j-control
          name="title"
          label="Headline Act"
        />

        <!-- Supporting Acts -->
        <j-control name="support" />

        <!-- Place -->
        <j-control name="place" />

        <!-- Date -->
        <j-control name="date" />
    </j-form>

    <!-- Article Body -->
    <draft-article-body :draft="draft" />
  </div>
</template>

<script>

import DraftArticleBody from './DraftArticleBody'

export default {
  name: 'DraftEventReview',
  components: {
    DraftArticleBody
  },
  props: ['draft']
}

</script>
